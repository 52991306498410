@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&family=Prata&family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 14px;
}

html, body {
	width: 100%;
	height: 100%;
}

body {
	margin: 0;
	--color-text: #000;
	--color-text-alt: #000;
	--color-bg: #ccc;
	--color-link: #000;
	--color-link-hover: #000;
	--color-link-alt: #000;
	--color-type: #000;
	--type-line-opacity: 0.02;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

a.link-alt {
	text-decoration: none;
	color: var(--color-link-alt);
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

main {
	position: relative;
	overflow: hidden;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: 1rem;
	margin-bottom: 2rem;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1000;
	text-transform: uppercase;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
}

.frame__tagline {
	color: var(--color-text-alt);
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child) {
	margin-right: 1rem;
}

.frame__author {
	display: block;
	width: 100%;
	margin-bottom: 1rem;
}

.item-wrap {
	text-transform: uppercase;

	display: grid;
	grid-template-columns: 50% 50%;
}

.item {
	--base: 7vh;
	--interval: 9vh;
	padding: 1rem;
	margin: 1rem 0;
	cursor: pointer;
	width: 100%;
	position: relative;
	flex: none;
	will-change: transform, opacity;
}

.item__img {
	width: 100%;
	/*border-radius: 12px;*/
	will-change: transform;
	-webkit-filter:  brightness(99%) contrast(125%) grayscale(100%) hue-rotate(0deg) invert(0%) opacity(80%) saturate(100%) sepia(50%);
	filter:  brightness(99%) contrast(125%) grayscale(100%) hue-rotate(0deg) invert(0%) opacity(80%) saturate(100%) sepia(90%);
	mix-blend-mode: none;
}

.item__caption-title {
	font-size: inherit;
}

.item__caption-description {
	display: none;
	text-transform: none;
}

.type {
	position: fixed;
	height: 100vmax;
	text-transform: uppercase;
	width: 100vmax;
	display: grid;
	justify-content: center;
	align-content: center;
	text-align: center;
	top: 50%;
	margin-top: -50vmax;
	will-change: transform;
}

.type__line {
	white-space: nowrap;
	font-size: 20vh;
	font-size: clamp(7rem,18.75vh,15rem);
	line-height: 0.8;
	font-weight: bold;
	font-family: 'Prata', cursive;
	color: var(--color-type);
	opacity: var(--type-line-opacity);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	will-change: transform, opacity;
}

.article-wrap {
	position: absolute;
	top: 5vh;
	width: 100%;
	justify-self: center;
	align-self: end;
	padding: 1rem;
	pointer-events: none;
}

.article {
	position: absolute;
	opacity: 0;
	pointer-events: none;
	display: grid;
	grid-template-columns: 1rem 1fr;
	width: 100%;
	align-items: start;
	grid-template-areas: 'img img'
						'number title'
						'intro intro'
						'desc desc';

}

.article--current {
	opacity: 1;
	pointer-events: auto;
	position: relative;
}

.article__img-wrap {
    width: 100%;
    height: 250px;
    overflow: hidden;
    grid-area: img;
    transform-origin: 50% 100%;
    margin-bottom: 1rem;
	/*border-radius: 17px 17px 0 0;*/
	-webkit-filter:  brightness(90%) contrast(125%) grayscale(100%) hue-rotate(0deg) invert(0%) opacity(100%) saturate(100%) sepia(50%);
	filter:  brightness(90%) contrast(125%) grayscale(100%) hue-rotate(0deg) invert(0%) opacity(100%) saturate(100%) sepia(50%);
	mix-blend-mode: none;
}

.article__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 35%;

}

.article__number {
	grid-area: number;
	font-weight: bold;
}

.article__title {
	grid-area: title;
	line-height: 0.85;
	font-size: 2vw;
	text-transform: uppercase;
	font-weight: bold;
	font-family: 'Prata', cursive;
	color: var(--color-type);
	position: relative;
	margin: 1rem 0 0.5rem;
	pointer: default;
	text-shadow: 2px 2px 1px #ccc;
}

.article__intro {
	grid-area: intro;
	font-weight: bold;
	padding-right: 2rem;
}

.article__description {
	grid-area: desc;
	overflow: hidden;
	padding-right: 2rem;
}

.back {
	background: none;
	border: 0;
	padding: 0;
	margin: 0 0 1rem 0;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    stroke: #000;
    z-index: 1000;
    cursor: pointer;
    width: 50px;
}

.back svg {
	stroke-linecap: round;
}

.back:hover,
.back:focus {
	outline: none;
	stroke: green;
}

@media screen and (min-width: 53em) {
	:root {
		font-size: 14px;
	}
	html, body {
		overflow: hidden;
	}
	.frame {
		position: fixed;
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		margin: 0;
		padding: 1rem 2vw;
		grid-template-columns: 40% 20% 40%;
		grid-template-areas: 'title author links';
	}
	.frame__title-wrap {
		grid-area: title;
		text-align: left;
	}
	.frame__title {
		margin: 0;
	}
	.frame__tagline {
		position: relative;
	}
	.frame__author {
		grid-area: author;
		justify-self: center;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: end;
	}
	.item-wrap {
		height: 100vh;
		display: flex;
		overflow: hidden;
		width: 100vw;
		position: relative;
		justify-content: flex-start;
		align-items: center;
	}
	.item {
		width: 21vw;
		padding: 0;
		margin: auto 2vw var(--base);
	}
	.item:nth-child(2) {
		margin-bottom: calc(var(--base) + var(--interval));
	}

	.item:nth-child(3) {
		margin-bottom: calc(var(--base) + var(--interval) * 2);
	}

	.item:nth-child(4) {
		margin-bottom: calc(var(--base) + var(--interval) * 3);
	}

	.item__caption-description {
		display: block;
	}

	.back {
		position: absolute;
		left: calc(((100vw - (38vw + 280px)) / 2) + 1.5rem);
	}
	.article-wrap {
		top: 10vh;
		height: 80vh;
		padding: 0;
	}
	.article {
		height: 100%;
		width: calc(38vw + 280px);
		margin: 0 auto;
		grid-template-rows: 3vw 2rem 3vw auto auto;
		grid-template-columns: 1.5rem 30% 1fr 1.5rem;
		grid-template-areas: '... ... img ...'
							'number ... img ...'
							'... title img ...'
							'... intro img ...'
							'... desc img ...';
	}
	.article__title {
		grid-column-end: 4; 
		margin: 0;
	}
	.article__intro {
		margin: 1.5rem 0;
	}
	.article__img-wrap {
		align-self: end;
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .article__img {
    	background-position: 50% 50%;
    }
}
